import { useTranslation } from "react-i18next";
import { IGrainPayload, IUIGrainInput } from "../../../interfaces/payloads/ICalculatorInput";
import { useCallback, useEffect, useState } from "react";
import { ValidationState } from "../../../classes/ValidationState";
import { IAutofillConfig, IGrainConfig } from "../../../interfaces/ICalculatorConfig";
import { ICalculatorOutput } from "../../../interfaces/ICalculatorOutput";
import { Button, IconButton, List, ListItem, SxProps, Theme, Typography } from "@mui/material";
import { fertilizerApi } from "../../../services/FertilizerApi";
import GridRow from "../../GridRow";
import OptionSelect from "../../inputs/OptionSelect";
import GridSection from "../../GridSection";
import { LargeSwitch } from "../../LargeSwitch";
import Loading from "../../Loading";
import SpacedInlineItems from "../../inputs/SpacedInlineItems";
import DecimalField from "../../inputs/DecimalField";
import { CultivatedPlant } from "../../../enums/calculator/CultivatedPlant";
import PlantSelect from "./PlantSelect";
import { OutputMode } from "../output/CalculatorOutput";
import { InfoOutlined } from "@mui/icons-material";
import GrainNInputForm from "./grain/GrainNInputForm";

const DefaultPTargetMax = 40;

function Title(props: { text: string, sx?: SxProps<Theme> }){
    return <Typography fontWeight={"bold"} sx={{...props.sx, marginTop: 'auto', marginBottom: 'auto'}}>
        {props.text}
    </Typography>
}

function Row({ children, spacing: padding }: { children: any, spacing?: number}) {
    return <GridRow sx={(t) => ({ display: 'inline-flex', width: '100%', marginTop: t.spacing(padding || 1) })}>{children}</GridRow>
};

function CalculatePMin(cropLevel: number, max:number, pYield: number, pfix?: number){
    const yieldMin = Math.round(cropLevel * pYield);
    const result = yieldMin;
    if(pfix) max = max - pfix;
    return max !== undefined && result > max ? max : result;
}

interface IProps {
    config: IGrainConfig;
    vState: ValidationState;
    output: (output: ICalculatorOutput)=>void;
    modeChanged: (mode: OutputMode) => void;
}
export default function GrainInputForm(props: IProps) {
    const { t } = useTranslation();

    const configInput = props.config.input;
    const yields  = props.config.yields;
    const vState = props.vState;
    vState.afterValidation = v => {
        setValid(prev => prev && v);
    }

    const [valid, setValid] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    
    const [input, setInput] = useState<IUIGrainInput>({...configInput});
    const [autofillConfig, setAutofillConfig] = useState<IAutofillConfig>();
    const [pTargetMax, setPTargetMax] = useState<number>(DefaultPTargetMax);
    const [pTargetMin, setPTargetMin] = useState<number>(0);
    const [cropLevel, setCropLevel] = useState<string>(String(input.cropLevel));
    const [showPfixInfo, setShowPFixInfo] = useState(false);

    const updateAutofillConfig = useCallback((plant?: CultivatedPlant, soil?: number) => {
        if (plant && soil)
            fertilizerApi.grainAutofillNConfig(
                plant, 
                soil
            ).then(setAutofillConfig);
        else
            setAutofillConfig(undefined);
    }, []);

    useEffect(() => {
        if(configInput.soil === 0) return;
        if(configInput.cultivatedPlant === 0) return;
        updateAutofillConfig(configInput.cultivatedPlant, configInput.soil);
        if (configInput.phosphorousClass > 0)
            fertilizerApi.autofillP(configInput.phosphorousClass, configInput.phosphorousFix).then(target => {
                const pMin = CalculatePMin(
                    configInput.cropLevel, 
                    target, 
                    yields.phosphorous, 
                    configInput.phosphorousFix
                );
                setPTargetMax(target);
                setPTargetMin(pMin);
                if(configInput.phosphorousTarget)
                    setInput(prev => {
                        prev.phosphorousTarget = configInput.phosphorousTarget;
                        configInput.phosphorousTarget = undefined;
                        configInput.phosphorousClass = -1;
                        return {...prev};
                    });
            });
        else
            setPTargetMin(CalculatePMin(
                configInput.cropLevel, 
                DefaultPTargetMax,
                yields.phosphorous, 
                configInput.phosphorousFix
            ));
    }, [updateAutofillConfig, configInput, yields.phosphorous]);
    
    useEffect(() => {
        if (input.phosphorousClass !== configInput.phosphorousClass
            ||
            input.phosphorousFix !== configInput.phosphorousFix
        ) {
            if (input.phosphorousClass > 0)
                fertilizerApi.autofillP(Number(input.phosphorousClass), input.phosphorousFix).then(target => {
                    setInput(prev => {
                        const min = CalculatePMin(
                            input.cropLevel, 
                            target, 
                            yields.phosphorous, 
                            input.phosphorousFix
                        );
                        const max = target;
                        setPTargetMax(max);
                        setPTargetMin(min);
                        prev.phosphorousTarget = target > max ? max : target;
                        if (prev.phosphorousTarget < min)
                            prev.phosphorousTarget = min;
                        return {...prev};
                    });
                });
            else setPTargetMax(DefaultPTargetMax);
        }
    }, [
        input.phosphorousClass, 
        input.phosphorousFix, 
        input.cropLevel,
        yields.phosphorous,
        configInput
    ]);

    const getPMin = useCallback((cropLevel:number, max:number) => {
        return CalculatePMin(cropLevel, max, yields.phosphorous, input.phosphorousFix);
    }, [input.phosphorousFix, yields])

    const createPayload = useCallback(() => {
        const copy: IUIGrainInput = JSON.parse(JSON.stringify(input));
        const payload = {
            batchInputs: copy.batchInputs,
            cropLevel: copy.cropLevel,
            cultivatedPlant: copy.cultivatedPlant,
            phosphorousClass: copy.phosphorousClass,
            phosphorousFix: copy.phosphorousFix,
            phosphorousTarget: copy.phosphorousTarget,
            soil: copy.soil
        } as IGrainPayload;
        return payload;
    }, [input]);
    
    const Send = useCallback(() => {
        if (!vState.valid) return;

        const payload = createPayload();
        setLoading(true);
        fertilizerApi.calculate(payload).then(result => {
            if (result) {
                input.batchInputs = payload.batchInputs;
                props.config.input = input;
                result.usedInput = payload;
                props.output(result);
            }
        }).finally(() => setLoading(false));
    }, [
        input,
        props,
        vState,
        createPayload
    ]);
    
    const PFixAllowed = useCallback((pClass: number) => {
        return pClass > 0 && pClass < 7 ;
    }, []);

    return (<GridSection>
        <Loading show={loading}/>
        <GridSection>
            <PlantSelect value={input.cultivatedPlant} vState={vState} onChange={(v) => {
                setInput(prev => {
                    if (v === CultivatedPlant.Unknown){
                        props.modeChanged(OutputMode.None);
                        return prev;
                    }
                    if (v === CultivatedPlant.Potato){
                        props.config.input = {...input};
                        props.config.input.cultivatedPlant = v;
                        props.modeChanged(OutputMode.Potato);
                        return prev;
                    }
                    if(v === CultivatedPlant.Silage) {
                        props.config.input = {...input};
                        props.config.input.cultivatedPlant = v;
                        props.modeChanged(OutputMode.Grass);
                        return prev;
                    }
                    prev.cultivatedPlant = v;
                    updateAutofillConfig(
                        prev.cultivatedPlant, 
                        prev.soil
                    );
                    return {...prev};
                });
            }}/>
            <Row><Title text={t('calculator.crop-level')}/></Row>
            <Row><DecimalField 
                id="crop-level"
                value={cropLevel}
                cornerInfo={`1000-15000`}
                max={5}
                required
                vState={vState}
                vRules={[
                    x => !isNaN(Number(cropLevel)),
                    x => Number(cropLevel) >= 1000,
                    x => Number(cropLevel) <= 15000
                ]}
                onChange={e => {
                    setCropLevel(e.target.value);
                    setInput(prev => {
                        if (e.target.value === ''){
                            prev.cropLevel = 0;
                            return {...prev};
                        }
                        const value = Number(e.target.value);
                        if (value >= 1000 && value <= 15000) {
                            const value = Number(e.target.value);
                            prev.cropLevel = isNaN(value) ? 0 : value;
                            setPTargetMin(getPMin(prev.cropLevel, pTargetMax));
                            return {...prev};
                        }
                        return prev;
                    });
                }}
            /></Row>
            <Row><Title text={t('calculator.phosphorous-class')}/></Row>
            <Row>
                <OptionSelect
                    id="p-class"
                    value={input.phosphorousClass}
                    required
                    vState={vState}
                    vRules={[
                        x => x > 0
                    ]}
                    options={props.config.qualityClasses} 
                    onChange={opt => {
                        setInput(prev => {
                            prev.phosphorousClass = opt.value;
                            if (!PFixAllowed(prev.phosphorousClass))
                                prev.phosphorousFix = 0;
                            return {...prev};
                        });
                    }}
                />
            </Row>
            {PFixAllowed(input.phosphorousClass) && <> 
                <Row>
                    <Title text={t('calculator.p-fix')}/>
                    <IconButton onClick={() => setShowPFixInfo(prev => !prev)}>
                        <InfoOutlined />
                    </IconButton>
                </Row>
                {showPfixInfo && <>
                    <Row>
                    <Typography>{t('calculator.input.p-fix-info')}</Typography>
                    </Row>
                    <List>
                        <ListItem>
                            <Typography><b>{t('calculator.input.p-3-title')}</b> {t('calculator.input.p-3-info')}</Typography>
                        </ListItem>
                        <ListItem>
                            <Typography><b>{t('calculator.input.p-6-title')}</b> {t('calculator.input.p-6-info')}</Typography>
                        </ListItem>
                    </List>
                </>}
                <Row>
                    <SpacedInlineItems id='pfix' items={props.config.pFixes.map((v, i) => {
                        return {
                            name: v > 0 ? `${v} kg P/${t('unit.hect')}` : t('common.no'),
                            node: <LargeSwitch checked={v === input.phosphorousFix} onChange={(x) => {
                                input.phosphorousFix = v;
                                setInput({...input});
                            }} />
                        };
                    })} />
                </Row>
            </>}
            {input.phosphorousClass > 0 && <><Row><Title text={t('calculator.p-target')}/></Row>
            <Row>
                <DecimalField 
                    id="p-target"
                    key={`p-target-${pTargetMin}-${pTargetMax}`}
                    value={input.phosphorousTarget}
                    cornerInfo={`${pTargetMin}-${pTargetMax}`}
                    vState={vState}
                    vRules={[
                        x => x >= pTargetMin,
                        x => x <= pTargetMax
                    ]}
                    disabled={input.phosphorousClass === 7}
                    onChange={e => {
                        setInput(prev => {
                            if (e.target.value === ''){
                                prev.phosphorousTarget = 0;
                            }
                            else {
                                const value = Number(e.target.value);
                                const pMax = pTargetMax;
                                prev.phosphorousTarget = isNaN(value) ? 0 : value;
                                if (prev.phosphorousTarget > pMax)
                                    prev.phosphorousTarget = pMax;
                            }
                            return {...prev};
                        });
                }}/>
            </Row></>}
            <Row><Title text={t('calculator.soil-type')}/></Row>
            <Row>
                <OptionSelect 
                    id={'soil'}
                    value={input.soil}
                    vState={vState}
                    vRules={[
                        x => x !== 0
                    ]}
                    required
                    options={props.config.soilTypes} 
                    onChange={opt => {
                        setInput(prev => {
                            prev.soil = opt.value;
                            updateAutofillConfig(
                                prev.cultivatedPlant, 
                                prev.soil
                            );
                            return {...prev};
                        });
                    }}
                />
            </Row>
            {autofillConfig && <GrainNInputForm 
                key={`${input.cultivatedPlant}-${input.soil}`}
                input={input}
                configInput={configInput}
                autofillConfig={autofillConfig}
                setValid={(v) => {
                    if(v !== valid)
                        setValid(v && vState.valid);
                }}
                config={props.config}
                onChange={(i) => {
                    setInput(prev => {
                        prev.batchInputs = i.batchInputs;
                        prev.plannedN = i.plannedN;
                        return prev;
                    });
                }}
            />}
            <Button onClick={Send} disabled={!valid} sx={{
                marginTop: '10px'
            }}>
                {t('calculator.get-best-solutions')}
            </Button>
        </GridSection>
        
    </GridSection>);
}