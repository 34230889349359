import { createTheme } from "@mui/material/styles";
import { fiFI } from '@mui/x-date-pickers/locales';

declare module "@mui/material/styles/createPalette" {
    interface Palette {
        Gray: Palette['primary'];
        Blue: Palette['primary'];
        Orange: Palette['primary'];
    }
    
    interface PaletteOptions {
        Gray: PaletteOptions['primary'];
        Blue: PaletteOptions['primary'];
        Orange: PaletteOptions['primary'];
    }
}

export const theme = createTheme({
    palette: {
        background: {
            default: '#f2efec'
        },
        primary: {
            main: '#2777B8',
            light: '#95bcd7'
        },
        info: {
            main: '#0C559C',
            light: '#F0F6F9'
        },
        secondary: {
            main: '#5A4E45'
        },
        warning: {
            main: '#ffe266'
        },
        error: {
            main: '#FF4A6B'
        },
        success: {
            main: '#76d77b'
        },
        Orange: {
            main: '#FF8E29'
        },
        Gray: {
            main: '#5A4E45',
            light: '#f3f6f9'
        },
        Blue: {
            main: '#00205B',
            light: '#ABDAF3'
        },
        text: {
            primary: '#232735',
            secondary: '#505565'
        }
    },
    typography: {
        allVariants: {
            color: '#232735',
            fontFamily: 'Noto Sans'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    borderStyle: 'solid',
                    borderWidth: '1px',
                    borderColor: theme.palette.primary.main,
                    backgroundColor: theme.palette.primary.main,
                    color: 'white',
                    justifyContent: 'center',
                    width: '100%',
                    borderRadius: '5px',
                    "&:hover": {
                        backgroundColor: theme.palette.primary.light,
                    },
                    margin: '1px'
                }),
                textSecondary: ({ ownerState, theme}) => ({
                    backgroundColor: '#dcdfe3',
                    borderColor: '#dcdfe3',
                    color: 'black'
                }),
                outlined: ({ ownerState, theme}) => ({
                    backgroundColor: 'white',
                    borderColor: theme.palette.primary.main,
                    color: theme.palette.primary.main,
                    "&:hover": {
                        backgroundColor: theme.palette.primary.main,
                        color: 'white',
                    }
                }),
            }
        },
        MuiListItemButton: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    borderBottomStyle: 'solid',
                    borderBottomWidth: '1px',
                    borderBottomColor: theme.palette.Gray.light,
                    color: theme.palette.Gray.main,
                    justifyContent: 'left',
                    paddingLeft: '20px',
                    "svg": {
                        marginTop: '5px',
                        marginBottom: '5px',
                        marginRight: '10px'
                    }
                })
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    padding: '9px',
                    '.MuiSwitch-track': {
                        borderRadius: '10px'
                    }
                })
             }
        },
        MuiInputBase: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    padding: 0,
                    borderRadius: '2px',
                    backgroundColor: '#ffffff',
                    'fieldset': {
                        borderColor: theme.palette.primary.main
                    },
                    'svg': {
                        color: theme.palette.primary.main
                    },
                    '&.Mui-error': {
                        'svg': {
                            color: theme.palette.error.main
                        }
                    },
                    'input': {
                        padding: '0px'
                    }
                })
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    borderRadius: '2px',
                    padding: '10px',
                    '&.readonly': {
                        'fieldset': {
                            borderColor: theme.palette.Gray.main
                        },
                    },
                })
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    '&.readonly': {
                        pointerEvents: 'none',
                        'fieldset': {
                            borderColor: theme.palette.Gray.main,
                        },
                    },
                })
            }
        },
        MuiSelect: {
            styleOverrides: {
                outlined: {
                    padding: '0'
                }
            },
            defaultProps: {
                fullWidth: true
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: ({ ownerState, theme }) => ({
                    borderRadius: 0
                })
            },
            defaultProps: {
                fullWidth: true
            }
        },
        MuiBadge: {
            styleOverrides: {
                root: {
                    'span': {
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        borderRadius: '8px'
                    }
                }
            }
        },
        MuiMenu: {
            styleOverrides: {
                root: {
                    maxHeight: '300px'
                }
            }
        }
    }
}, fiFI);