import { Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { IInfoBox } from "../../../interfaces/ICalculatorOutput";
import { TextToHtml } from "../../../classes/TextToHtml";

interface IProps {
    box: IInfoBox;
}
export default function InfoBox({ box }: IProps){

    const { t } = useTranslation();
    const [textToHtml] = useState(new TextToHtml());
    
    //const [data] = useState(box.infoRows);

    return <Table sx={(t) => ({
        backgroundColor: t.palette.warning.main, 
        '.MuiTableCell-root': { 
            borderBottom: 'none',
            padding: '10px',
            paddingBottom: '0px',
            paddingTop: '0px'
        }
    })}>
        <TableBody>
            {box.title && <TableRow>
                <TableCell><Typography variant="subtitle2" fontWeight={'bold'}>{t(box.title || 'info-box.title')}</Typography></TableCell>
            </TableRow>}
            {box.content.length > 0 && <TableRow>
                {box.content.map((info,i) => 
                    <TableCell key={`info-content-row-${i}`}>
                        <Typography variant="caption">{t(info)}</Typography>
                    </TableCell>)
                }
            </TableRow>}
            <InfoBoxContent rows={box.infoRows} />
            {box.child && <>
                <TableRow>
                    <TableCell colSpan={5}>
                        {box.child.title && <Typography variant="subtitle2" fontWeight={'bold'}>{t(box.child.title)}</Typography>}
                    </TableCell>
                </TableRow>
                <InfoBoxContent rows={box.child.infoRows} />
            </>}
            {box.footer && <TableRow>
                <TableCell>{textToHtml.toElements(t(box.footer), 'subtitle2')}</TableCell>
            </TableRow>}
        </TableBody>
    </Table>
}

function InfoBoxContent(props: { rows: string[][] }){

    const { t } = useTranslation();
    
    const format = useCallback((value: string[]) => {
        return value.slice(1).map(t).join(' ');
    }, [t]);

    return <TableRow> 
        <TableCell colSpan={5} sx={{
            padding: '5px'
        }}>
        <ul style={{
            paddingLeft: '27px'
        }}>
            {props.rows.map((item,i) => <li key={`info-box-row-${i}`}>
                <b>{t(item[0])}</b>{format(item)}
            </li>)}
        </ul>
        </TableCell>
    </TableRow>
}