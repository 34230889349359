import { INotification } from "../../interfaces/INotification";
import Notification from './Notification';
import { useCallback, useEffect, useState } from "react";
import { Box, Button, Divider, LinearProgress } from "@mui/material";
import { notificationStore } from "../../services/NotificationStore";
import CachedIcon from '@mui/icons-material/Cached';

interface IProps {
    id: string;
    top?: number;
    history?: true;
    readonly?: true;
    manualReload?: true;
}
export default function NotificationList(props: IProps) {
    
    const [loading, setLoading] = useState<boolean>(false);
    const [open, setOpen] = useState<string|null>(null);
    const [notifications, setNotifications] = useState<INotification[]>([]);
    const [history, setHistory] = useState<INotification[]|null>(props.history ? [] : null);
    
    const forceReload = useCallback(async () => {
        setLoading(true);
        await notificationStore.load();
        await notificationStore.loadHistory();
        update();
        setLoading(false);
    }, []);

    const update = useCallback(() => {
        if (props.history)
            setHistory(notificationStore.getHistory());
        const data = notificationStore.get();
        if(props.top)
            setNotifications([...data.filter((x,i) => i < (props.top || 0))]);
        else
            setNotifications([...data]);
    }, [props.history, props.top]);

    const init = useCallback(async () => {
        if (props.history && notificationStore.getHistory().length <= 0) {
            setLoading(true);
            await notificationStore.loadHistory();
        }
        if (await notificationStore.needReload()){
            setLoading(true);
            await notificationStore.reload();
        }
        update();
        setLoading(false);
    }, [props.history, update]);

    useEffect(() => {
        init();
        // eslint-disable-next-line
    }, []);

    return (<Box sx={{ width: '100%' }}>
            {loading && <LinearProgress sx={{width:'100%'}}/>}
            {props.manualReload && <Button onClick={() => forceReload()}><CachedIcon /></Button>}
            {notifications.map(notification => (
                <Notification 
                    key={`notification-new-${notification.id}`}
                    onOpen={() => setOpen(notification.id)}
                    onClose={() => setOpen(null)}
                    open={notification.id === open}
                    notification={notification}
                    onRemove={update}
                    readonly={props.readonly}
                />
            ))}
            {history && (<>
                <Divider sx={t => ({ marginTop: t.spacing(2), marginBottom: t.spacing(2)})}/>
                {history.map(notification => (
                    <Notification 
                        key={`notification-old-${notification.id}`}
                        onOpen={() => setOpen(notification.id)}
                        onClose={() => setOpen(null)}
                        open={notification.id === open}
                        notification={notification}
                        readonly
                    />
                ))}
            </>)}
    </Box>);
}