import { Typography } from "@mui/material";
import { features } from "../../consts/features";
import AngledBar from "../AngledBar";
import { useTranslation } from "react-i18next";

const appLink = process.env.REACT_APP_ANDROID_APP_LINK;

export default features.empty('MobileAppAd') ||  function MobileAppAd(){
    const { t } = useTranslation();
    const openAppStore = () => {
        window.open(appLink, '_blank');
    }
    return (<AngledBar sx={{
        textAlign: 'center'
    }} onClick={openAppStore}>
        <Typography variant="subtitle1" color={'white'}>{t('ads.mobile-app')}</Typography>
    </AngledBar>);
}