import {useCallback, useEffect, useState } from "react";
import Loading from "../components/Loading";
import AppContainer from "../components/appContainers/AppContainer";
import { features } from "../consts/features";
import { fertilizerApi } from "../services/FertilizerApi";
import { ICalculatorOutput } from "../interfaces/ICalculatorOutput";
import { useTranslation } from "react-i18next";
import { IGrainConfig, IGrassConfig, IPotatoConfig } from "../interfaces/ICalculatorConfig";
import GrassInputForm from "../components/calculator/input/GrassInputForm";
import CalculatorOutput, { OutputMode } from "../components/calculator/output/CalculatorOutput";
import HeaderCloseButton from "../components/buttons/HeaderCloseButton";
import GrainInputForm from "../components/calculator/input/GrainInputForm";
import { IOption } from "../interfaces/IOption";
import { QualityClassIcons } from "../components/icons/QualityClasses";
import { ValidationState } from "../classes/ValidationState";
import { CultivatedPlant } from "../enums/calculator/CultivatedPlant";
import PotatoInputForm from "../components/calculator/input/PotatoInputForm";

type IConfig = IGrassConfig|IGrainConfig|IPotatoConfig;

const addQualityIcons = (qualityClasses: IOption<number>[]) => {
    for(let qClass of qualityClasses){
        qClass.node = QualityClassIcons[qClass.value]({ width: 20, style: { 
            position: 'absolute',
            right: '35px',
            top: '12px'
        } });
    }
}

const transferCommonConfigVars = (
    newConfig: IConfig,
    config?: IConfig
) => {
    if(config){
        newConfig.input.cultivatedPlant = config.input.cultivatedPlant;
    }
}

const getGrassConfig = (config?: IConfig) => {
    return fertilizerApi.grassConfig().then(newConfig => {
        transferCommonConfigVars(newConfig, config);
        newConfig.input.useManure = false;
        addQualityIcons(newConfig.qualityClasses);
        return newConfig;
    });
};

const getGrainConfig = (config?: IConfig) => {
    return fertilizerApi.grainConfig().then(newConfig => {
        transferCommonConfigVars(newConfig, config);
        addQualityIcons(newConfig.qualityClasses);
        return newConfig;
    });
};

const getPotatoConfig = (config?: IConfig) => {
    return fertilizerApi.potatoConfig().then(newConfig => {
        transferCommonConfigVars(newConfig, config);
        addQualityIcons(newConfig.qualityClasses);
        return newConfig;
    });
}

export default features.empty('Calculator') || function Calculator() {
    
    const { t } = useTranslation();

    const [loading, setLoading] = useState<boolean>(true);

    const [mode, setMode] = useState<OutputMode>(OutputMode.None);
    const [vState, setVState] = useState<ValidationState>(new ValidationState());
    const [config, setConfig] = useState<IConfig>();
    const [output, setOutput] = useState<ICalculatorOutput>();

    useEffect(() => {
        fertilizerApi.get<IOption<CultivatedPlant>[]>('calculator/plants').then(plants => {
            plants = plants.filter(x => x.value !== 0).sort((a,b) => t(a.text).localeCompare(t(b.text)));
            const plant = plants[0].value;
            if (plant === CultivatedPlant.Silage)
                ChangeMode(OutputMode.Grass, plant);
            else
                ChangeMode(OutputMode.Grain, plant);
        });
    }, []);

    const ChangeMode = useCallback(async (mode: OutputMode, plant?: CultivatedPlant) => {
        setLoading(true);
        switch (mode){
            case OutputMode.Grass:
                setConfig(await getGrassConfig(config));
                break;
            case OutputMode.Grain:
                setConfig(await getGrainConfig(config));
                break;
            case OutputMode.Potato:
                setConfig(await getPotatoConfig(config));
                break;
            case OutputMode.None:
                setConfig(undefined);
                break;
        }
        if(plant)
            setConfig(prev => {
                if(prev){
                    prev.input.cultivatedPlant = plant;
                    return {...prev};
                }
                return prev;
            });
        setVState(new ValidationState());
        setMode(mode);
        setLoading(false);
    }, [config]);

    return <AppContainer
        title={t('calculator.title')}
        actionButton={output ? () => <HeaderCloseButton onClick={() => setOutput(undefined)}/> : undefined}
        style={{
            padding: 10,
            overflowAnchor: 'none'
        }}
    >
        <Loading show={loading}/>
        {mode > 0 && !output && config && !loading && <>
            {mode === OutputMode.Grass && <GrassInputForm vState={vState} output={setOutput} config={config as IGrassConfig} modeChanged={ChangeMode}/>}
            {mode === OutputMode.Grain && <GrainInputForm vState={vState} output={setOutput} config={config as IGrainConfig} modeChanged={ChangeMode}/>}
            {mode === OutputMode.Potato && <PotatoInputForm vState={vState} output={setOutput} config={config as IPotatoConfig} modeChanged={ChangeMode}/>}
        </>}
        {mode > 0 && config && output && <CalculatorOutput mode={mode} config={config} output={output}/>}
    </AppContainer>
}