import React from "react";
import { useEffect, useState } from "react";

// export enum DeviceType {
//     Mobile,
//     Desktop,
//     Unknown
// }
export class DeviceType {
    public isNative: boolean = false;
    constructor(public mobile: boolean = false) {
        this.isNative = !!(window as any).isNativeApp;
    }
}
const DeviceContext = React.createContext<DeviceType>(new DeviceType());

interface IProps {
    children: React.ReactNode;
}
export default function DeviceProvider(props: IProps){

    const [device, setDevice] = useState<DeviceType>(new DeviceType());

    useEffect(() => {
        const userAgent = navigator.userAgent.toLowerCase();
        const isMobile = /iphone|ipad|ipod|android|windows phone/g.test(userAgent);
        const isTablet =
          /(ipad|tablet|playbook|silk)|(android(?!.*mobile))/g.test(userAgent);
          
        if (isMobile) setDevice(new DeviceType(true));
        else if (isTablet) setDevice(new DeviceType(true));
        else setDevice(new DeviceType());
    }, []);

    return (
        <DeviceContext.Provider value={device}>
            {props.children}
        </DeviceContext.Provider>
    );
}

export const useDevice = () => React.useContext(DeviceContext)