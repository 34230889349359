import { useEffect, useState } from "react";
import { IReport } from "../../interfaces/IReport";
import { yaraApi } from "../../services/YaraApi";
import TwinChartContainer from "./common/TallChartContainer";
import Loading from "../Loading";
import HorizontalBar from "./common/HorizontalBarChart";

interface IProps {
    startDate: string;
    endDate: string;
}
export default function Top10UsersReport({ startDate, endDate }: IProps){
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IReport[]>([]);
    
    useEffect(() => {
        setLoading(true);
        yaraApi.getReport('topUsers', startDate, endDate).then(setData).finally(() => setLoading(false));
    }, [startDate, endDate]);
    
    return loading || !data ? <Loading show /> : <TwinChartContainer 
        charts={[
            <HorizontalBar loading={loading} data={data[0]}  pointName="report.score"/>,
            <HorizontalBar loading={loading} data={data[1]}  pointName="report.score"/>
        ]}
        height="550px"
    />;
}