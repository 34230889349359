import { Grid } from "@mui/material";
import { IReportSummary } from "../../../interfaces/IReport";
import ChartSummaryTable from "./ChartSummaryTable";


interface IProps {
    children: React.ReactNode;
    summary?: IReportSummary;
}
export default function ChartContainer(props: IProps){
    return (
        <div style={{
            display: 'flex',
            width: '100%',
        }}>
            <Grid item xs={props.summary ? 8 : 12} sx={t => ({
                marginRight: t.spacing(2),
            })}>
            {props.children}
            </Grid>
            {props.summary && <Grid item xs={4}>
                <ChartSummaryTable 
                    data={props.summary}
                />
            </Grid>}
        </div>
    );
}