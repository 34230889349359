import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { AuthProvider, AuthProviderProps } from "oidc-react";

import { UserManagerInstance } from "./users/userManagerProvider";

import Loading from './components/Loading';

import { IUserInfo } from './interfaces/IUserInfo';

import Home  from './pages/Home';
import Deliveries from './pages/Deliveries';
import TermsAndConditions from './pages/TermsAndConditions';
import Storage from './pages/Storage';
import { routes } from './consts/routes';
import Settings from './pages/Settings';
import Logout from './pages/Logout';
import FirstTimeLogin from './pages/FirstTimeLogin';
import DeliveryTimeRequest from './pages/delivery/DeliveryTimeRequest';
import DeliveryIssueReport from './pages/delivery/DeliveryIssueReport';
import Survey from './pages/Survey';
import { features } from './consts/features';
import DeliverySyncList from './pages/delivery/DeliverySyncList';
import Notifications from './pages/Notifications';
import Calculator from './pages/Calculator';
import FertilizerGuide from './pages/FertilizerGuide';
import NotificationsMobile from './pages/NotificationsMobile';
import Admin from './pages/Admin';

const basePath = process.env.REACT_APP_LOCATION || '';

// OpenId Connect configurations usermanager needs be created to avoid multiple instances of it due some async issues
// within the library.
const oidcConfig: AuthProviderProps = {
    onSignIn: async (user: any) => {
        const codeStart = window.location.href.indexOf('?code');
        const login_url = user?.profile?.login_url;
        if(login_url)
            window.location.href = login_url;
        else if (codeStart > -1)
            window.location.href = window.location.href.substring(0, codeStart);
    },
    userManager: UserManagerInstance
};

interface IProps {
    loadUser: () => void;
    user: IUserInfo|null;
}
export default features.empty('AppAuthorized') || function AppAuthorized({user, loadUser}: IProps) {

    useEffect(() => {
        if(!user) loadUser();
    }, [loadUser, user]);
  
    const updateTermsAccepted = (accepted: boolean) => {
        if(!user) return;
        loadUser();
    }

    return (
        <AuthProvider {...oidcConfig}>
            <BrowserRouter basename={basePath}>
                <Loading show={user == null}/>
                {user && (
                <Routes>
                    {user.isAdminUser && (<>
                        <Route path={routes.Admin} element={<Admin />} />
                    </>)}
                    {user.termsAccepted && (<>
                        <Route path={routes.Home} element={<Home user={user}></Home>} />
                        <Route path={routes.Delivery.List} element={<Deliveries />} />
                        <Route path={routes.Delivery.TimeRequest} element={<DeliveryTimeRequest />} />
                        <Route path={routes.Delivery.IssueReport} element={<DeliveryIssueReport />} />
                        <Route path={routes.Survey} element={<Survey/>} />
                        <Route path={routes.Notifications} element={<Notifications/>} />
                        <Route path={routes.NotificationsMobile} element={<NotificationsMobile />}/>
                        {user.sbkAccess && (<>
                        <Route path={routes.Delivery.Sync} element={<DeliverySyncList />}/>
                        <Route path={routes.Storage} element={<Storage user={user} />} />
                        </>)}
                        <Route path={routes.Settings} element={<Settings user={user} />} />
                        <Route path={routes.TermsAndConditions} element={<TermsAndConditions user={user} onTermsAccepted={updateTermsAccepted} />} />
                        <Route path={routes.Calculator} element={<Calculator />} />
                        <Route path={routes.EBook} element={<FertilizerGuide user={user}/>} />
                    </>)}
                    {!user.termsAccepted && (
                        <Route path={'*'} element={<TermsAndConditions user={user} onTermsAccepted={updateTermsAccepted} />}/>
                    )}
                    <Route path={routes.FirstTimeLogin} element={<FirstTimeLogin />}/>
                    <Route path={routes.Logout} element={<Logout />} />
                </Routes>
                )}
            </BrowserRouter>
        </AuthProvider>
    );
}