import { useTranslation } from "react-i18next";
import { ValidationState } from "../../../classes/ValidationState";
import { IAutofillConfig, IPotatoConfig } from "../../../interfaces/ICalculatorConfig";
import { ICalculatorOutput } from "../../../interfaces/ICalculatorOutput";
import { OutputMode } from "../output/CalculatorOutput";
import { IPotatoPayload, IUIPotatoInput } from "../../../interfaces/payloads/ICalculatorInput";
import { useCallback, useEffect, useState } from "react";
import GridSection from "../../GridSection";
import Loading from "../../Loading";
import PlantSelect from "./PlantSelect";
import { CultivatedPlant } from "../../../enums/calculator/CultivatedPlant";
import GridRow from "../../GridRow";
import { Theme } from "@emotion/react";
import { Button, SxProps, Typography } from "@mui/material";
import OptionSelect from "../../inputs/OptionSelect";
import { PotatoType } from "../../../enums/calculator/PotatoType";
import DecimalField from "../../inputs/DecimalField";
import { fertilizerApi } from "../../../services/FertilizerApi";
import { useMessager } from "../../../providers/MessagerProvider";

function Title(props: { text: string, sx?: SxProps<Theme> }){
    return <Typography fontWeight={"bold"} sx={{...props.sx, marginTop: 'auto', marginBottom: 'auto'}}>
        {props.text}
    </Typography>
}

function Row({ children, spacing: padding }: { children: any, spacing?: number}) {
    return <GridRow sx={(t) => ({ display: 'inline-flex', width: '100%', marginTop: t.spacing(padding || 1) })}>{children}</GridRow>
};

interface IProps {
    config: IPotatoConfig;
    vState: ValidationState;
    output: (output: ICalculatorOutput)=>void;
    modeChanged: (mode: OutputMode) => void;
}
export default function PotatoInputForm(props: IProps){
    const { t } = useTranslation();
    const messager = useMessager();

    const configInput = props.config.input;
    const vState = props.vState;
    
    const [loading, setLoading] = useState<boolean>(false);
    const [input, setInput] = useState<IUIPotatoInput>({...configInput});
    const [autofillConfig, setAutofillConfig] = useState<IAutofillConfig>();
    const [pmax, setPmax] = useState<number|null>(null);
    
    useEffect(() => {
        if(input.potatoType === 0) return setAutofillConfig(undefined);
        if(input.soilClass === 0) return setAutofillConfig(undefined);
        fertilizerApi.potatoAutofillNConfig(
            input.potatoType,
            0, 
            input.soilClass
        ).then(c => {
            setAutofillConfig(c);
        });
    }, [
        input.soilClass,
        input.potatoType
    ]);
    useEffect(() =>  {
        if(input.phosphorousClass === 0) return;
            setPmax(null);
        fertilizerApi.potatoAutofillP(input.phosphorousClass).then(p => {
            setPmax(p);
        });
    }, [input.phosphorousClass])

    const createPayload = useCallback(() => {
        const copy: IUIPotatoInput = JSON.parse(JSON.stringify(input));
        const payload = {
            productionType: copy.productionType,
            potatoType: copy.potatoType,
            soilClass: copy.soilClass,
            cultivatedPlant: copy.cultivatedPlant,
            phosphorousClass: copy.phosphorousClass,
            potassiumClass: copy.potassiumClass,
            phosphorousTarget: copy.phosphorousTarget,
            // highQPutrifier: copy.highQPutrifier,
            // putrifier: copy.putrifier,
            batchInputs: copy.batchInputs
        } as IPotatoPayload;
        return payload;
    }, [input]);
    
    const Send = useCallback(() => {
        if (!vState.valid) return;

        const payload = createPayload();
        setLoading(true);
        fertilizerApi.calculate(payload).then(result => {
            if (result) {
                input.batchInputs = payload.batchInputs;
                props.config.input = input;
                result.usedInput = payload;
                props.output(result);
            }
        }).finally(() => setLoading(false));
    }, [
        input,
        props,
        vState,
        createPayload
    ]);
    
    return (<GridSection>
        <Loading show={loading} />
        <GridSection>
            <PlantSelect value={input.cultivatedPlant} vState={vState} onChange={(v) => {
                setInput(prev => {
                    if (v === CultivatedPlant.Unknown){
                        props.modeChanged(OutputMode.None);
                        return prev;
                    }
                    if(v === CultivatedPlant.Silage) {
                        props.config.input = {...input};
                        props.config.input.cultivatedPlant = v;
                        props.modeChanged(OutputMode.Grass);
                        return prev;
                    }
                    if (v !== CultivatedPlant.Potato){
                        props.config.input = {...input};
                        props.config.input.cultivatedPlant = v;
                        props.modeChanged(OutputMode.Grain);
                        return prev;
                    }
                    prev.cultivatedPlant = v;
                    return {...prev};
                });
            }}/>
            <Row><Title text={t('calculator.potatoType')}/></Row>
            <Row>
                <OptionSelect 
                    id='potato-type'
                    value={input.potatoType}
                    required
                    vState={vState}
                    vRules={[
                        x => x > 0
                    ]}
                    options={props.config.potatoTypes}
                    onChange={opt => {
                        setInput(prev => {
                            prev.potatoType = opt.value;
                            return {...prev};
                        })
                    }}
                />
            </Row>
            {input.potatoType !== PotatoType.Seed && (<>
                <Row><Title text={t('calculator.soilClass')}/></Row>
                <Row>
                    <OptionSelect 
                        id='potato-soil-class'
                        value={input.soilClass}
                        required
                        vState={vState}
                        vRules={[
                            x => x > 0
                        ]}
                        options={props.config.soilClasses}
                        onChange={opt => {
                            setInput(prev => {
                                prev.soilClass = opt.value;
                                return {...prev};
                            });
                        }}
                    />
                </Row>
            </>)}
            <Row><Title text={t('calculator.phosphorous-class')}/></Row>
            <Row>
                <OptionSelect
                    id="p-class"
                    value={input.phosphorousClass}
                    required
                    vState={vState}
                    vRules={[
                        x => x > 0
                    ]}
                    options={props.config.qualityClasses} 
                    onChange={opt => {
                        setInput(prev => {
                            prev.phosphorousClass = opt.value;
                            return {...prev};
                        });
                    }}
                />
            </Row>
            <Row><Title text={t('calculator.potassium-class')}/></Row>
            <Row>
                <OptionSelect
                    id="k-class"
                    value={input.potassiumClass}
                    required
                    vState={vState}
                    vRules={[
                        x => x > 0
                    ]}
                    options={props.config.qualityClasses} 
                    onChange={opt => {
                        setInput(prev => {
                            prev.potassiumClass = opt.value;
                            return {...prev};
                        });
                    }}
                />
            </Row>
            {autofillConfig && (<> 
                <Row><Title text={t('calculator.potato-n-1')}/></Row>
                <Row>
                    <DecimalField 
                        id="planned-n"
                        key={`planned-n-${input.cultivatedPlant}-${input.soil}`}
                        cornerInfo={`≤${autofillConfig.maxN}`}
                        value={input.batchInputs[0].input} 
                        hideZero
                        vState={vState}
                        vRules={[
                            x => x > 0,
                            x => x <= autofillConfig.maxN
                        ]}
                        onChange={e => {
                            setInput(prev => {
                                if (e.target.value === ''){
                                    prev.batchInputs[0].input = 0;
                                }
                                else {
                                    const value = Number(e.target.value);
                                    prev.batchInputs[0].input = isNaN(value) ? 0 : value;
                                }
                                return {...prev};
                            });
                        }}
                    />
                </Row>
            </>)}
            {pmax && (<>
                <Row><Title text={t('calculator.potato-p')}/></Row>
                <Row>
                    <DecimalField 
                        id="planned-p"
                        key={`planned-p-${input.cultivatedPlant}-${input.soil}`}
                        cornerInfo={`≤${pmax}`}
                        value={input.phosphorousTarget} 
                        hideZero
                        vState={vState}
                        vRules={[
                            x => x > 0,
                            x => x <= pmax
                        ]}
                        onChange={e => {
                            setInput(prev => {
                                if (e.target.value === ''){
                                    prev.phosphorousTarget = 0;
                                }
                                else {
                                    const value = Number(e.target.value);
                                    prev.phosphorousTarget = isNaN(value) ? 0 : value;
                                }
                                if (prev.phosphorousTarget > pmax){
                                    messager.error(t('calculator.p-max-exceeded', {max: pmax}));
                                }
                                return {...prev};
                            });
                        }}
                    />
                </Row>
            </>)}
            <Button onClick={Send} disabled={!vState.valid} sx={{
                marginTop: '10px'
            }}>
                {t('calculator.get-best-solutions')}
            </Button>
        </GridSection>
    </GridSection>);
}