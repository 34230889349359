import { useEffect, useState } from "react";
import NotificationList from "../components/notifications/NotificationList";
import { notificationStore } from "../services/NotificationStore";
import Loading from "../components/Loading";

export default function NotificationsMobile() {

    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        notificationStore.needReload().then(async (needReload) => {
            if (needReload)
                await notificationStore.reload();
            await notificationStore.loadHistory();
        }).finally(() => setLoading(false));
        notificationStore.preventReload();

        return () => {
            notificationStore.enableReload();
            notificationStore.clearHistory();
        }
    }, []);

    return (<>
        <Loading show={loading}/>
        {!loading && <NotificationList history manualReload id="new"/>}
    </>);
}