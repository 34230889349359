import { Box } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import 'dayjs/locale/fi';
import { ServerDateFormat } from "../../consts/dateFormat";

interface IProps {
    value: [string, string];
    onChange: (value: [string, string]) => void;
}
export default function DateRange(props: IProps){
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(dayjs(props.value[0]));
    const [endDate, setEndDate] = useState(dayjs(props.value[1]));

    useEffect(() => {
        props.onChange([startDate.format(ServerDateFormat), endDate.format(ServerDateFormat)]);
    }, [startDate, endDate, props]);

    return <Box sx={t => ({ 
            display: 'flex', 
            flexDirection: 'row', 
            gap: 2,
            margin: t.spacing(2)
        })}>
            <DatePicker
                views={['month', 'year', 'day']}
                label={t('common.start-date')}
                value={startDate}
                onChange={(x) => {
                    if (x) setStartDate(x);
                }}
            />
            <DatePicker
                views={['month', 'year', 'day']}
                label={t('common.end-date')}
                value={endDate}
                onChange={(x) => {
                    if (x) setEndDate(x);
                }}
            />
        </Box>
}