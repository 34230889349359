import { routes } from "../consts/routes";
import { INavItem } from "../interfaces/INavItem";
import { access } from "../services/AccessService";

import CartIcon from "./icons/CartIcon";
import StorageIcon from "./icons/StorageIcon";
import DamagedBagIcon from "./icons/DamagedBagIcon";
import InfoIcon from "./icons/InfoIcon";
import ExitIcon from "./icons/ExitIcon";
import SettingsIcon from "./icons/SettingsIcon";
import HomeIcon from "./icons/HomeIcon";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { disabled } from "../consts/features";
import CalculatorIcon from "./icons/CalculatorIcon";
import NewFeature from "./labels/NewFeature";
import { yaraApi } from "../services/YaraApi";
import AutoStoriesOutlinedIcon from '@mui/icons-material/AutoStoriesOutlined';

export function createNavItems(
    t: (s: string) => string,
    navigate: (s: string) => void,
    filter?: (x: INavItem) => boolean
): INavItem[] {
    const logAndNavigte = (s: string, log: string) => {
        yaraApi.action(`page>>${log}`);
        navigate(s);
    }
    return !access.loggedIn ? [
        {
            key: 'login',
            hasAccess: true,
            icon: HomeIcon,
            title: t('common.log-in'),
            action: () => navigate(routes.Home)
        }
    ] : [
        {
            key: 'admin',
            hasAccess: access.admin,
            icon: () => <AdminPanelSettingsOutlinedIcon />,
            title: t('pages.admin'),
            action: () => logAndNavigte(routes.Admin, 'admin')
        },
        {
            key: 'frontpage',
            hasAccess: true,
            icon: HomeIcon,
            title: t('pages.frontpage'),
            action: () => logAndNavigte(routes.Home, 'home')
        },
        {
            key: 'orders-and-deliveries',
            hasAccess: true,
            icon: CartIcon,
            title: t('pages.orders-and-deliveries'),
            action: () => logAndNavigte(routes.Delivery.List, 'delivery-list')
        },
        {
            key: 'issue-report',
            hasAccess: true,
            icon: DamagedBagIcon,
            title: t('pages.issue-report'),
            action: () => logAndNavigte(routes.Delivery.IssueReport, 'issue-report')
        },
        {
            key: 'storage',
            hasAccess: access.sbkAccess,
            icon: StorageIcon,
            title: t('pages.storage'),
            action: () => logAndNavigte(routes.Storage, 'sbk')
        },
        {
            key: 'calculator',
            hasAccess: !disabled('Calculator'),
            icon: CalculatorIcon,
            title: <>{t('calculator.title')} <NewFeature featureKey="Calculator"/></>,
            action: () => logAndNavigte(routes.Calculator, 'calculator')
        },
        {
            key: 'topical',
            hasAccess: true,
            icon: InfoIcon,
            iconSizeMod: -8,
            title: t('pages.topical'),
            href: 'https://www.yara.fi/uutiset-ja-tapahtumat/',
            //target: '_blank'
        },
        {
            key: 'test-results',
            hasAccess: true,
            icon: InfoIcon,
            iconSizeMod: -8,
            title: t('pages.test-results'),
            href: 'https://www.yara.fi/koetulokset2023/'
        },
        {
            key: 'fertilizer-guide',
            hasAccess: true,
            icon: () => (<AutoStoriesOutlinedIcon />),
            iconSizeMod: -8,
            title: t('pages.fertilizer-guide'),
            action: () => logAndNavigte(routes.EBook, 'fertilizer-guide')
        },
        {
            key: 'customer-benefits',
            hasAccess: !access.userIsSeller,
            icon: InfoIcon,
            iconSizeMod: -8,
            title: t('pages.customer-benefits'),
            href: 'https://www.yara.fi/asiakasedut/'
        },
        {
            key: 'ftl',
            hasAccess: !!localStorage.getItem('ftl'),
            icon: () => (<ArrowRightAltIcon fontSize="large" color={'primary'}/>),
            title: t('pages.first-time-login'),
            action: () => navigate(routes.FirstTimeLogin.replace(':id', localStorage.getItem('ftl') || ''))
        },
        {
            key: 'settings',
            hasAccess: true,
            icon: SettingsIcon,
            iconSizeMod: -4,
            title: t('pages.settings'),
            action: () => logAndNavigte(routes.Settings, 'settings')
        },
        {
            key: 'logout',
            hasAccess: true,
            icon: ExitIcon,
            title: t('pages.logout'),
            action: () => navigate(routes.Logout)
        }
    ].filter(x => x.hasAccess).filter(filter || (() => true));
}