
import { useCallback, useEffect, useState } from "react";
import { yaraApi } from "../../services/YaraApi";

import { IReport } from "../../interfaces/IReport";
import CommonBarChart from "./common/CommonBarChart";
import CommonLineChart from "./common/CommonLineChart";
import TallChartContainer from "./common/TallChartContainer";

interface IDashboardReports {
    logins?: IReport;
    newUsers?: IReport;
    marketing?: IReport;
    navigations?: IReport;
}
interface IProps {
    startDate: string;
    endDate: string;
}
export default function DashboardReport({ startDate, endDate }: IProps){
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IDashboardReports>({});

    const load = useCallback(async () => {
        setLoading(true);
        const logins = await yaraApi.getReport('logins', startDate, endDate);
        const newUsers = await yaraApi.getReport('new-users', startDate, endDate);
        const marketing = await yaraApi.getReport('marketing', startDate, endDate);
        const navigations = await yaraApi.getReport('navigations', startDate, endDate);
        setData(prev => {
            var d = { 
                logins: logins[0],
                newUsers: newUsers[1],
                marketing: marketing[1],
                navigations: navigations[1]
            };
            d.marketing.title = 'report.names.marketing';
            d.newUsers.title = 'report.names.new-users';
            d.navigations.title = 'report.names.navigations';
            return d;
        });
        setLoading(false);
    }, [startDate, endDate]);

    useEffect(() => {
        load();
    }, [load]);

    return <>
    <CommonBarChart loading={loading} data={data.logins} />
    <TallChartContainer 
        charts={[
            <CommonLineChart loading={loading} data={data.newUsers} />,
            <CommonLineChart loading={loading} data={data.marketing} />,
        ]}
    />
    <CommonLineChart loading={loading} data={data.navigations} />
    </>;
}