import { IReport } from "../../../interfaces/IReport";
import CommonTable from "../../CommonTable";
import Loading from "../../Loading";



interface IProps {
    loading: boolean;
    data?: IReport;
}
export default function CommonChartTable({loading, data}: IProps){
    if (!data?.summary) return <></>;

    return loading || !data ? <Loading show /> : <CommonTable columns={data.summary.columns} data={data.summary.rows} />;
}