import { Grid, Typography } from "@mui/material";
import { IOption } from "../../interfaces/IOption";
import CardListActionItem from "../CardListActionItem";

const left = 2;
const right = 12-left;;

interface IProps {
    selected: IOption<string>;
    options: IOption<string>[];
    children: any;
    onSelect: (value: IOption<string>) => void;
}
export default function ViewListContainer(props: IProps){

    return <Grid container spacing={2}>
        <Grid item xs={left}>
            {props.options.map((x, i) => {
                return <CardListActionItem key={i} onClick={() => props.onSelect(x)}>
                    <Typography variant='body1' color={x.value === props.selected.value ? 'primary' : 'initial'}>{x.text}</Typography>
                </CardListActionItem>
            })}
        </Grid>
        <Grid item xs={right}>
            {props.children}
        </Grid>
    </Grid>
}