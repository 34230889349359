import { Grid, ListItemButton, Typography, useTheme } from "@mui/material";
import { CommonProps } from "@mui/material/OverridableComponent";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { UIDateFormat } from "../../consts/dateFormat";
import { IDelivery } from "../../interfaces/IDelivery";
import { yaraApi } from "../../services/YaraApi";
import DeliveryItem from "./DeliveryItem";

import PdfIcon from "../icons/PdfIcon";
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { disabled } from "../../consts/features";

export default function DeliveryContent(props: IDelivery & CommonProps){
    const { t } = useTranslation();
    const theme = useTheme();

    const [loading, setLoading] = useState<boolean>(false);

    const downloadPdf = (fileId: string | undefined) => {
        if(!fileId) return;
        setLoading(true);
        yaraApi.getIssueReportPdf(fileId).finally(() => setLoading(false));
    }

    return (<>
        {props.errors?.map((error, i) => (
            <Grid key={`${props.nr}-error-${i}`} sx={(t) => ({
                paddingTop: t.spacing(1),
                paddingLeft: t.spacing(2),
                display: 'inline-flex'
            })}>
                <ListItemButton 
                    color="primary"
                    onClick={() => downloadPdf(error.fileId)}
                    disabled={loading || !error.fileId}
                    sx={t => ({
                        border: 'none',
                        padding: 0,
                        opacity: 'inherit !important'
                    })}
                >
                    {!error.fileId && <MoreHorizOutlinedIcon color="primary" fontSize="small"/>}
                    {error.fileId && <PdfIcon fontSize="small" fill={theme.palette.primary.main} />}
                    <Typography variant="subtitle2" color="primary">
                        {t('delivery.item-error-reported')}&nbsp;
                        {moment(error.reportDate).format(UIDateFormat)}
                    </Typography>
                </ListItemButton>
            </Grid>
        ))}
        {props.items.map((item, i) => (
            <DeliveryItem key={`delivery-item-${i}`} item={item} />
        ))}
        {props.deliveryAddress && (
        <Grid container sx={t => ({ marginTop: t.spacing(1), marginBottom: t.spacing(2)})}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body1" fontWeight="bold">{t('delivery.delivery-address')}</Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} sx={t => ({ paddingLeft: t.spacing(1)})}>
                <Typography variant="body1">{t(props.deliveryAddress)}</Typography>
            </Grid>
        </Grid>
        )}
        {props.desiredDelivery && !disabled('DeliveryTimeRequest') && !props.deliveredDate && (
        <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12}>
                <Typography variant="body1">
                    {t('delivery.delivery-time-request')}: {t('common.week')} {props.desiredDelivery.week} {props.desiredDelivery.year}
                </Typography>
            </Grid>
        </Grid>
        )}
    </>);
}