import { useTranslation } from "react-i18next";
import AppContainer from "../components/appContainers/AppContainer";

import CalculatorUsageReport from "../components/charts/CalculatorUsageReport";
import { useCallback, useState } from "react";
import FirstTimeLoginLinkReport from "../components/charts/FirstTimeLoginLinkReport";
import DateRange from "../components/inputs/DateRange";
import { IOption } from "../interfaces/IOption";
import ViewListContainer from "../components/viewContainers/ViewListContainer";
import dayjs from "dayjs";
import { ServerDateFormat } from "../consts/dateFormat";
import NavigationsReport from "../components/charts/NavigationsReport";
import MarketingReport from "../components/charts/MarketingReport";
import NewUsersReport from "../components/charts/NewUsersReport";
import NpsReport from "../components/charts/NpsReport";
import SmsReport from "../components/charts/SmsReport";
import LoginsReport from "../components/charts/LoginsReport";
import Top10UsersReport from "../components/charts/Top10UsersReport";
import SbkReport from "../components/charts/SbkReport";
import ReclamationsReport from "../components/charts/ReclamationsReport";
import PurchasedTonsReport from "../components/charts/PurchasedTonsReport";
import DashboardReport from "../components/charts/Dashboard";
import SellerActivationReport from "../components/charts/SellerActivationReport";

const defaultStartDate = () => {
    return dayjs().startOf('M');
}

export default function Admin(){
    const { t } = useTranslation();
    const [startDate, setStartDate] = useState(defaultStartDate().add(-1, 'month').format(ServerDateFormat));
    const [endDate, setEndDate] = useState(defaultStartDate().format(ServerDateFormat));

    const [options] = useState<IOption<string>[]>([
        { value: '', text: t('Dashboard') },
        { value: 'logins', text: t('report.names.logins') },
        { value: 'newUsers', text: t('report.names.new-users') },
        { value: 'sellerActivations', text: t('report.names.seller-activations') },
        { value: 'firstTimeLoginLinks', text: t('report.names.first-time-login-links') },
        { value: 'marketing', text: t('report.names.marketing') },
        { value: 'navigations', text: t('report.names.navigations') },
        { value: 'calculatorUsage', text: t('report.names.calculator-usage') },
        { value: 'nps', text: t('report.names.nps') },
        { value: 'sms', text: t('report.names.smss') },
        { value: 'top10', text: t('report.names.top-10-users') },
        { value: 'sbk', text: t('report.names.sbk') },
        { value: 'reclamations', text: t('report.names.reclamations') },
        { value: 'purchases', text: t('report.names.purchased-tons') },
    ]);
    const [selectedOption, setSelectedOption] = useState<IOption<string>>(options[0]);

    const SelectedView = useCallback(() => {
        switch(selectedOption.value){
            case '': return (<DashboardReport startDate={startDate} endDate={endDate} />);
            case 'logins': return (<LoginsReport startDate={startDate} endDate={endDate} />);
            case 'calculatorUsage': return (<CalculatorUsageReport startDate={startDate} endDate={endDate} />);
            case 'firstTimeLoginLinks': return (<FirstTimeLoginLinkReport startDate={startDate} endDate={endDate} />);
            case 'navigations': return (<NavigationsReport startDate={startDate} endDate={endDate} />);
            case 'marketing': return (<MarketingReport startDate={startDate} endDate={endDate} />);
            case 'newUsers': return (<NewUsersReport startDate={startDate} endDate={endDate} />);
            case 'sellerActivations': return (<SellerActivationReport startDate={startDate} endDate={endDate} />);
            case 'nps': return (<NpsReport startDate={startDate} endDate={endDate} />);
            case 'sms': return (<SmsReport startDate={startDate} endDate={endDate} />);
            case 'top10': return (<Top10UsersReport startDate={startDate} endDate={endDate} />);
            case 'sbk': return (<SbkReport startDate={startDate} endDate={endDate} />);
            case 'reclamations': return (<ReclamationsReport startDate={startDate} endDate={endDate} />);
            case 'purchases': return (<PurchasedTonsReport startDate={startDate} endDate={endDate} />);
            default: return (<div>{t('common.no-data')}</div>);
        }
    }, [selectedOption, startDate, endDate, t]);

    return (
        <AppContainer title={t('pages.admin')}>
            <ViewListContainer selected={selectedOption} options={options} onSelect={opt => setSelectedOption({...opt})}>
                <DateRange key={'admin-date-range'} value={[startDate, endDate]} onChange={([start, end]) => {
                    setStartDate(start);
                    setEndDate(end);
                }} />
                {<SelectedView />}
            </ViewListContainer>
        </AppContainer>
    );
}