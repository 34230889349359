import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";



export default function Disclaimer({context, content}: {context: string, content: string[]}){
    const { t } = useTranslation();
    return <Grid container sx={(t) => ({
        marginTop: t.spacing(2),
        marginBottom: t.spacing(2),
        paddingBottom: t.spacing(1),
        borderBottom: '1px solid',
        borderBottomColor: t.palette.primary.main
    })}>
        {content.map((text, i) => <Typography key={`${context}-disclaimer-${i}`} variant="body1">
            {t(`report.disclaimers.${text}`)}
        </Typography>)}
    </Grid>
}