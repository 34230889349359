import { useState, useEffect, Suspense, useCallback } from 'react';
import { ThemeProvider } from '@mui/material/styles'; 
import { MessagerProvider } from './providers/MessagerProvider';
import { theme } from './content/theme';
import './App.css';

import { yaraApi } from './services/YaraApi';

import moment from 'moment';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import 'dayjs/locale/fi';
import Loading, { useLoading } from './components/Loading';

import { CssBaseline } from '@mui/material';
import { routes } from './consts/routes';
import AppAuthorized from './AppAuthorized';
import AppAnonymous from './AppAnonymous';
import { access } from './services/AccessService';
import { UserManagerInstance } from './users/userManagerProvider';
import { paramService } from './services/ParamService';
import { deliveryStore } from './services/DeliveryStore';
import i18n from './i18n';
import { IUserInfo } from './interfaces/IUserInfo';
import AxiosInterceptors from './interceptors/AxiosInterceptors';
import { notificationStore } from './services/NotificationStore';
import { fertilizerApi } from './services/FertilizerApi';
import DeviceProvider from './providers/DeviceProvider';
import { LocalizationProvider } from '@mui/x-date-pickers';

moment.locale("fi");

const routeAllowsAnonymous = () => {
    var href = window.location.href;
    for(let route of routes.allowAnonymous){
        if (href.indexOf(route) >= 0){
            return true;
        }
    }
}

function App() {
    const loading =  useLoading();
    yaraApi.loadingHider = loading.hide;
    
    const [user, setUser] = useState<IUserInfo|null>(null);
    const [AppComponent, setAppComponent] = useState<JSX.Element>();

    const loadUser = useCallback(async () => {
        const user = await UserManagerInstance.getUser();
        if (user == null) return;
    
        const setLocale = (locale: string | undefined, user: IUserInfo) => {
            if (!user.settings.culture)
                user.settings.culture = locale === 'sv'
                ? 'sv-SE'
                : 'fi-FI';
            i18n.changeLanguage(user.settings.culture);
        };

        const userInfo = await yaraApi.getUser();
        setLocale(user.profile.locale, userInfo);
    
        await paramService.load();
        await deliveryStore.load();
        await notificationStore.load();
      
        access.loggedIn = true;
        access.admin = userInfo.isAdminUser;
        access.userIsSeller = userInfo.isSeller;
        access.sbkAccess = userInfo.sbkAccess;
        setUser(userInfo);
    }, []);

    const initApp = useCallback(async () => {
        const loggedInUser = await UserManagerInstance.getUser();

        if (user) return;
        if (loggedInUser){
            await loadUser();
            return;
        }
        if (routeAllowsAnonymous()){
            i18n.changeLanguage('unknown');
            setAppComponent(<AppAnonymous reload={initApp}/>)
            return;
        }

        setAppComponent(<AppAuthorized user={user} loadUser={loadUser}/>);
    }, [loadUser, user]);

    useEffect(() => {
        if(user) setAppComponent(<AppAuthorized user={user} loadUser={loadUser}/>);
    }, [user, loadUser])

    useEffect(() => {
        initApp();
    }, [initApp]);

    return (
        <DeviceProvider>
            <MessagerProvider apis={[yaraApi, fertilizerApi]}>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="fi">
                        <CssBaseline />
                        <AxiosInterceptors />
                        <Suspense fallback={<Loading show={true}/>}>
                            {AppComponent ? AppComponent : <Loading show={true}/>}
                        </Suspense>
                    </LocalizationProvider>
                </ThemeProvider>
            </MessagerProvider>
        </DeviceProvider>
    );
}

export default App;
