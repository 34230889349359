import { Fragment, useState } from "react";
import { IBatch, ICalculatorOutput, IFertilizer, IInfoBox, IRecommendation } from "../../../../interfaces/ICalculatorOutput";
import { renderToString } from 'react-dom/server';
import { decimalFormat, decimalFormatUp } from "../../../../consts/decimalFormat";
import { useTranslation } from "react-i18next";
import { ICalculatorConfig } from "../../../../interfaces/ICalculatorConfig";
import PdfInputSummary from "./PdfInputSummary";
import CalculatorPdfHeader from "./PdfHeader";
import CalculatorFooter from "../CalculatorFooter";
import CalculatorHeader from "../CalculatorHeader";
import { IUIGrainInput, IUIGrassInput, IUIPotatoInput } from "../../../../interfaces/payloads/ICalculatorInput";
import { CultivatedPlant } from "../../../../enums/calculator/CultivatedPlant";
import { OutputMode } from "../CalculatorOutput";
import { Typography } from "@mui/material";

const fontSize = '16px';
const Distributor = ({children}: { children: any} ) => (<div style={{
    backgroundColor: '#ABDAF3',
    fontWeight: 'bold',
    padding: '5px',
    paddingLeft: '5%',
    fontSize: fontSize
}}>
    {children}
</div>)
const Header = ({children}: { children: any} ) => (<tr style={{
    backgroundColor: '#F0F6F9',
    fontWeight: 'normal',
    padding: '5px',
    paddingLeft: '5%',
    fontSize: fontSize
}}>
    {children}
</tr>)
const HeaderGroup = ({children}: { children: any} ) => (<th colSpan={3} style={{
    backgroundColor: '#F0F6F9',
    fontWeight: 'normal', 
    padding: '5px',
    fontSize: fontSize
}}>
    {children}
</th>)
const Cell = ({children, border}: { children: any, border?: boolean} ) => (<td style={{
    fontWeight: 'normal', 
    padding: '5px',
    minWidth: '150px',
    maxWidth: '150px',
    fontSize: fontSize,
    borderTop: border ? '2px solid black' : ''
}}>
    {children}
</td>)
const Cell2 = ({children, border}: { children: any, border?: boolean} ) => (<td style={{
    fontWeight: 'normal', 
    padding: '5px',
    width: `10%`,
    minWidth: '50px',
    maxWidth: '50px',
    fontSize: fontSize,
    borderTop: border ? '2px solid black' : ''
}}>
    {children}
</td>)

export function ContentHtml(config: ICalculatorConfig, output: ICalculatorOutput){
    return `
    ${renderToString(<>
        <CalculatorPdfHeader/>
        <CalculatorPdfContent config={config} output={output}/>
    </>)}
    `;
}

function NPKHeader() {
    const { t } = useTranslation();
    return <>
        <Cell2>N {t('calculator.fertilizer-unit')}</Cell2>
        <Cell2>P {t('calculator.fertilizer-unit')}</Cell2>
        <Cell2>K {t('calculator.fertilizer-unit')}</Cell2>
    </>
}
function FertilizerCells({f, prefix}: {f: IFertilizer, prefix?: string}){
    const { t } = useTranslation();
    let name = f.fertilizer.name;
    if (f.addInfo){
        name += ` (${t(f.addInfo)})`;
    }
    if (prefix){
        name = `${prefix} ${name}`;
    }
    return (<>
    <Cell>{name}</Cell>
    <Cell>{f.quantity}</Cell>
    <Cell2>{decimalFormatUp(f.fertilizerNutrients.nitrogen)}</Cell2>
    <Cell2>{decimalFormat(f.fertilizerNutrients.phosphorous, 1)}</Cell2>
    <Cell2>{decimalFormat(f.fertilizerNutrients.potassium)}</Cell2>
    </>)
}
function TotalNutrientsCells(f: IFertilizer){
    return (<>
    <Cell2>{decimalFormat(f.totalNutrients.nitrogen)}</Cell2>
    <Cell2>{decimalFormat(f.totalNutrients.phosphorous, 1)}</Cell2>
    <Cell2>{decimalFormat(f.totalNutrients.potassium)}</Cell2>
    </>)
}
function FullHeader({grass, title, i}: {grass: boolean, title: string, i: number}){
    const { t } = useTranslation();
    return <>
    {i === 0 && <tr>
        {grass ? (<th style={{width: '150px'}}></th>) : (<th></th>)}
        <th></th>
        <HeaderGroup>{t('calculator.fertilizer-nutrients')}</HeaderGroup>
        {grass && <HeaderGroup>{t('calculator.fertilizer-and-manure-nutrients')}</HeaderGroup>}
    </tr>}
    <Header>
        <Cell>{title}</Cell>
        <Cell>{t('calculator.fertilizer-quantity-grass')}</Cell>
        <NPKHeader />
        {grass && <NPKHeader />}
    </Header>
    </>
}
function Harvests({grass, harvest, distributor}: {grass: boolean, harvest: IRecommendation, distributor: IBatch}){
    const { t } = useTranslation();
    return (<>
        {harvest.fertilizers.map(f => (<Fragment key={`${distributor.name}-${harvest.number}-${f.fertilizer.code}`}>
            <tr>
                <FertilizerCells f={f}/>
                {grass && <TotalNutrientsCells {...f}/>}
            </tr>
            {f.alt && (<tr style={{backgroundColor: '#fff488'}}>
                <FertilizerCells f={f.alt} prefix={t('common.or')}/>
            </tr>)}
        </Fragment>))}
    </>)
}
function Summaries({harvest, distributor}: {harvest: IRecommendation, distributor: IBatch}){
    const { t } = useTranslation();
    return (<>
    {harvest.summaries.map((sum,i) => (<Fragment key={`${distributor.name}-${harvest.number}-sum-${i}`}>
        <tr>
            <Cell border={i === 0}>{t(sum.name)}</Cell>
            <Cell border={i === 0}> </Cell>
            <Cell2 border={i === 0}>{decimalFormat(sum.nitrogen)}</Cell2>
            <Cell2 border={i === 0}>{decimalFormat(sum.phosphorous, 1)}</Cell2>
            <Cell2 border={i === 0}>{decimalFormat(sum.potassium)}</Cell2>
        </tr>
    </Fragment>))}
    </>)
}

interface IProps {
    config: ICalculatorConfig;
    output: ICalculatorOutput;
}
export default function CalculatorPdfContent({ config, output }: IProps){
    const { t } = useTranslation();

    const input: IUIGrassInput&IUIGrainInput&IUIPotatoInput = output.usedInput as any;

    const [mode] = useState<OutputMode>(
        input.cultivatedPlant === CultivatedPlant.Silage ? OutputMode.Grass :
        input.cultivatedPlant === CultivatedPlant.Potato ? OutputMode.Potato :
        OutputMode.Grain
    );
    const [grass] = useState(mode === OutputMode.Grass);
    const [grain] = useState(mode === OutputMode.Grain);
    const [potato] = useState(mode === OutputMode.Potato);

    return <div style={{
        fontSize: fontSize,
        marginTop: '20px',
        paddingTop: '20px',
        fontFamily: 'Noto Sans'
    }}>
        <PdfInputSummary input={output.usedInput} config={config} mode={mode}/>
        {grass && <CalculatorHeader />}
        {grass && <Typography sx={{width:'100%'}}>{t('calculator.info.header.2')}</Typography>}
        {grass && <Typography sx={{width:'100%'}}>{t('calculator.info.nutrient-max')}</Typography>}
        {grain && <Typography sx={{width:'100%'}}>{t('calculator.output.balance-desc')}</Typography>}
        N max: {output.maxNutrients.nitrogen}
        |
        P max: {output.maxNutrients.phosphorous}
        {potato && <>
        | K {t('common.recommendation').toLowerCase()}: {output.maxNutrients.potassium}
        </>}
        {output.batches.map(distributor => (
            <Fragment key={`${distributor.name}`}>
                <Distributor key={distributor.name}>
                    {distributor.name !== '' ? t(`retailer.${distributor.name}`) : ''}
                </Distributor>
                <table style={{ width: '100%'}}>
                    <tbody>
                    {distributor.recommendations.map((harvest, i) => (<Fragment key={`${distributor.name}-${harvest.number}`}>
                        <FullHeader grass={grass} title={`${harvest.number}. ${grass ? t('calculator.harvest') : t('calculator.solution')}`} i={i}/>
                        <Harvests grass={grass} harvest={harvest} distributor={distributor}/>
                        <Summaries harvest={harvest} distributor={distributor}/>
                    </Fragment>))}
                    </tbody>
                </table>
                {potato && <table style={{ width: '100%'}}>
                    <tbody>
                    {distributor.recommendations.map(r => r.alt).map((harvest, i) => (harvest &&<Fragment key={`${distributor.name}-${harvest.number}`}>
                        <FullHeader grass={grass} title={`${harvest.number}. ${t('calculator.solution')} + ${t('calculator.extra-fertilizer').toLowerCase()}`} i={i}/>
                        <Harvests grass={grass} harvest={harvest} distributor={distributor}/>
                        <Summaries harvest={harvest} distributor={distributor}/>
                    </Fragment>))}
                    </tbody>
                </table>}
            </Fragment>
        ))}
        {grass && <CalculatorFooter />}
        {output.infoBox && <PdfInfoBox box={output.infoBox} />}
    </div>;
}

function PdfInfoBox({ box }: { box: IInfoBox }){
    const { t } = useTranslation();

    const format = (value: string[]) => {
        return value.slice(1).map(t).join(' ');
    };

    return <table style={{
        backgroundColor: '#F0F6F9',
        padding: '5px',
        width: '100%',
        fontSize: fontSize
    }}>
        <tbody>
            {box.title && <tr>
                <td><b>{t(box.title || 'info-box.title')}</b></td>
            </tr>}
            {box.content.length > 0 && <tr>
                {box.content.map((info,i) => 
                    <td key={`info-content-row-${i}`}>
                        <Typography variant="caption">{t(info)}</Typography>
                    </td>)
                }
            </tr>}
            <tr> 
                <td colSpan={5} style={{
                    padding: '5px'
                }}>
                <ul style={{
                    paddingLeft: '27px'
                }}>
                    {box.infoRows.map((item,i) => <li key={`info-box-row-${i}`}>
                        <b>{t(item[0])}</b>{format(item)}
                    </li>)}
                </ul>
                </td>
            </tr>
        </tbody>
    </table>
}