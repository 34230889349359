import { useState } from "react";
import { Typography, TypographyProps, styled } from "@mui/material";
import moment from "moment";
import { UIDateFormat } from "../../consts/dateFormat";
import { useTranslation } from "react-i18next";

const DeliveryArrivalDateContainer = styled('div')(({ theme }) => ({
    display: 'flex'
}));

interface IProps  {
    completed: boolean;
    deliveredDate?: string;
    estimatedDelivery?: string;

    showExplanation?: true;
}
export default function DeliveryArrivalDate(props: IProps & TypographyProps){
    const { t } = useTranslation();

    const {
        completed,
        deliveredDate,
        estimatedDelivery,
        
        showExplanation,

        fontWeight
    } = props;

    const [date] = useState<string|null>(
        completed ? null :
        deliveredDate ? moment(deliveredDate).format(UIDateFormat) :
        estimatedDelivery ? estimatedDelivery :
        null
    );

    const [explanation] = useState<string>((
        completed ? null :
        deliveredDate ? null :
        (showExplanation && estimatedDelivery && estimatedDelivery.includes('-')) ? t('delivery.estimate-explanation')
        : null
    ) || '');

    return date ? (<DeliveryArrivalDateContainer>
        <Typography variant="subtitle1" sx={{fontWeight: fontWeight || 'bold', whiteSpace: 'nowrap'}}>
            {date}
        </Typography><br/>
        {explanation && (
            <Typography variant="caption" color={'primary'} fontWeight={fontWeight}>
                &nbsp;({explanation})
            </Typography>
        )}
    </DeliveryArrivalDateContainer>) : (<></>);
}