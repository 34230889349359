
import { useEffect, useState } from "react";
import { yaraApi } from "../../services/YaraApi";

import { IReport } from "../../interfaces/IReport";
import CommonBarChart from "./common/CommonBarChart";
import CommonLineChart from "./common/CommonLineChart";
import Disclaimer from "./common/Disclaimer";

interface IProps {
    startDate: string;
    endDate: string;
}
export default function MarketingReport({ startDate, endDate }: IProps){
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IReport[]>([]);
    
    useEffect(() => {
        setLoading(true);
        yaraApi.getReport('marketing', startDate, endDate).then(setData).finally(() => setLoading(false));
    }, [startDate, endDate]);

    return <>
        <Disclaimer context="marketing" content={[
            'marketing',
            'marketing-2'
        ]}/>
        <CommonBarChart loading={loading} data={data[0]} stacked/>
        <CommonLineChart loading={loading} data={data[1]} />
    </>;
}