import { TableRow, TableCell } from "@mui/material";
import { useTranslation } from "react-i18next";


interface IProps {
    listKey: string;
    remarks: string[];
}
export default function RemarksAndRecomms({ listKey, remarks }: IProps){
    const { t } = useTranslation();

    const open = remarks.length > 0;

    return open ? (<TableRow sx={(t) => ({backgroundColor: t.palette.warning.main})}>
        <TableCell colSpan={5} sx={{padding: '5px'}}>
        <ul>{remarks.map((item,i) => <li key={`${listKey}-${i}`}>{t(item)}</li>)}</ul>
        </TableCell>
    </TableRow>) : <></>;
};