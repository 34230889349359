import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { ChartColors } from "../../../consts/ChartColors";
import { IReport } from "../../../interfaces/IReport";
import Loading from "../../Loading";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    ChartDataLabels,
    Title,
    Tooltip,
    Legend
);

interface IProps {
    loading: boolean;
    data?: IReport;
    pointName?: string;
    contained?: true;
    stacked?: boolean;
}
export default function HorizontalBar(props: IProps) {
    const { t } = useTranslation();
     
    const {
        loading, 
        data,
        ...options
    } = props;

    return loading || !data ? <Loading show /> : <Bar
        options={{
            indexAxis: 'y',
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    labels: data.datasetValueTitles.map(x =>  ''),
                    stacked: options.stacked,
                    grid: {
                        display: false
                    },
                }
            },
            plugins: {
                legend: {
                    display: true,
                    position: 'bottom',
                },
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            var label = t(data.datasets[context.datasetIndex].pointName || ' ');
                            if (label) {
                                label += ': ';
                            }
                            if (label && context.parsed.x !== null) {
                                label += context.parsed.x;
                            }
                            return label;
                        }
                    }
                },
                datalabels: {
                    color: '#000',
                    align: 'end',
                    anchor: 'start',
                    formatter: function (value, context) {
                        return context.chart.data.labels?.[context.dataIndex] || '';
                    },
                    font: (c) => ({
                        size: 16
                    }),
                }
            }
        }}
        data={{
            labels: data.datasetValueTitles.map(t),
            datasets: data.datasets.map((dataset, i) => ({
                label: t(data.datasetTitles[i]),
                barThickness: 40,
                backgroundColor: ChartColors[dataset.color],
                data: dataset.values,
            }))
        }}
    />
}