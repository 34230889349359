import { Grid, SxProps, Theme } from "@mui/material";

interface IProps {
    children?: any;
    sx?: SxProps<Theme>;
    onClick?: () => void;
}
export default function AngledBar(props: IProps){
    return (<Grid item sx={(t) => ({
        padding: t.spacing(1),
        backgroundColor: t.palette.primary.main,
        paddingRight: '50px',
        position: 'relative',
        ':before': {
            content: '""',
            position: 'absolute',
            top: 0,
            right: 0,
            borderBottom: '100px solid white',
            borderLeft: '100px solid transparent',
        },
        ':hover': {
            cursor: 'pointer',
            backgroundColor: t.palette.primary.light,
        },
        ...props.sx
    })} onClick={props.onClick}>
        {props.children}
    </Grid>);
}