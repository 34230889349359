import { Grid } from "@mui/material";

interface IProps {
    charts: React.ReactNode[];
    height?: string;
}
export default function TallChartContainer(props: IProps){
    const width = 12 / props.charts.length;
    return (
        <div style={{
            display: 'flex',
            width: '100%',
        }}>
            {props.charts.map((chart, index) => (
                <Grid key={index} item xs={width} sx={t => ({
                    marginRight: t.spacing(2),
                    height: props.height
                })}>
                    {chart}
                </Grid>
            ))}
        </div>
    );
}