import { Accordion, AccordionSummary, AccordionDetails, TableContainer, Table, TableHead, TableRow, TableBody, Typography, Box, TableCell, TableCellProps, Button } from "@mui/material";
import { decimalFormatUp, decimalFormat } from "../../../consts/decimalFormat";
import { IBatch, ICalculatorOutput, IFertilizer, IRecommendation } from "../../../interfaces/ICalculatorOutput";
import GridRow from "../../GridRow";
import ExtraFertilization from "../../icons/ExtraFertilization";
import InitialFertilization from "../../icons/InitialFertilization";
import { OutputMode } from "./CalculatorOutput";
import { useState, useCallback, Fragment } from "react";
import { useTranslation } from "react-i18next";
import ExpandModeIcon from '@mui/icons-material/ExpandMore';
import RemarksAndRecomms from "./RemarksAndRecomms";
import InfoBox from "./InfoBox";

enum RowDataVersion {
    Total = 0,
    Manure = 1,
    Fertilizer = 2
}

function Title(props: { text: string }){
    return <Typography fontWeight={"bold"} sx={{ marginTop: 'auto', marginBottom: 'auto'}}>
        {props.text}
    </Typography>
}

function Cell(props: { children: any} & TableCellProps) {
    const {
        children,
        sx,
        ...tableProps
    } = props;
    return (<TableCell sx={{
        padding: '0 10px 0 0',
        maxWidth: '140px', 
        width: '10%',
        minWidth: '30px',
        ...sx
    }} {...tableProps}>
        <Typography>{children}</Typography>
    </TableCell>)
}
function FirstCell(props: { children: any} & TableCellProps) {
    const {
        children,
        sx,
        ...tableProps
    } = props;
    return (<TableCell sx={{
        padding: '10px 0 10px 10px',
        maxWidth: '100%', 
        ...sx
    }} {...tableProps}>
        {children}
    </TableCell>)
}
function LastCell(props: { children: any} & TableCellProps) {
    const {
        children,
        sx,
        ...tableProps
    } = props;
    return (<TableCell sx={{
        padding: '0 10px 0 0',
        maxWidth: '150px', 
        width: '10%',
        minWidth: '40px',
        ...sx
    }} {...tableProps}>
        <Typography>{children}</Typography>
    </TableCell>)
}

interface IProps {
    mode: OutputMode;
    output: ICalculatorOutput;
    distributor: IBatch
}
export default function DistributorOutput(props: IProps){

    const { t } = useTranslation();
    
    const distributor = props.distributor;

    const [grass] = useState(props.mode === OutputMode.Grass);
    const [grain] = useState(props.mode === OutputMode.Grain);
    const [potato] = useState(props.mode === OutputMode.Potato);

    const [showAlts, setShowAlts] = useState<boolean>(false);

    const [rowDataVersion, setRowDataVersion] = useState<RowDataVersion>(grass 
        ? RowDataVersion.Total
        : RowDataVersion.Fertilizer
    );

    const [output] = useState<ICalculatorOutput>(props.output);

    const ProductName = useCallback(({fert, prefix }: {fert: IFertilizer, prefix?: string}) => {
        let name = fert.fertilizer.name;
        if (fert.addInfo){
            name += ` (${t(fert.addInfo)})`;
        }
        if (prefix){
            name = `${prefix} ${name}`;
        }
        return <Typography>{name}</Typography>
    }, [t]);

    const RowData = useCallback(({fert}: {fert: IFertilizer}) => {
        switch (rowDataVersion){
            case RowDataVersion.Total:
                return <>
                    <Cell align="right">{fert.quantity}</Cell>
                    <Cell align="right">{decimalFormatUp(fert.totalNutrients.nitrogen)}</Cell>
                    <Cell align="right">{decimalFormat(fert.totalNutrients.phosphorous, 1)}</Cell>
                    <LastCell align="right">{decimalFormat(fert.totalNutrients.potassium)}</LastCell>
                </>
            case RowDataVersion.Manure:
                return <>
                    <Cell align="right"> </Cell>
                    <Cell align="right">{decimalFormatUp(fert.manureNutrients.nitrogen)}</Cell>
                    <Cell align="right">{decimalFormat(fert.manureNutrients.phosphorous, 1)}</Cell>
                    <LastCell align="right">{decimalFormat(fert.manureNutrients.potassium)}</LastCell>
                </>
            case RowDataVersion.Fertilizer:
                return <>
                    <Cell align="right">{fert.fertilizer.code && fert.quantity}</Cell>
                    <Cell align="right">{decimalFormatUp(fert.fertilizerNutrients.nitrogen)}</Cell>
                    <Cell align="right">{decimalFormat(fert.fertilizerNutrients.phosphorous, 1)}</Cell>
                    <LastCell align="right">{decimalFormat(fert.fertilizerNutrients.potassium)}</LastCell>
                </>
        }
    }, [rowDataVersion]);

    const RowDataVersionButton = useCallback(({ title, version}: {title: string, version: RowDataVersion}) => {
        return <Button color={rowDataVersion === version ? 'primary' : 'secondary' }
            onClick={() => setRowDataVersion(version)}
            sx={(t) => ({
                borderRadius: 0
            })}
        >
            {t(`calculator.output.${title}`)}
        </Button>
    }, [t, rowDataVersion]);

    const hasDistributor = distributor.name !== '';
    
    const recommendations = showAlts 
        ? distributor.recommendations.map(r => r.alt).filter(r => r != null) as IRecommendation[]
        : distributor.recommendations;

    const infobox = !distributor.infoBoxes ? null : showAlts
        ? distributor.infoBoxes[1]
        : distributor.infoBoxes[0];

    return <Accordion disableGutters expanded={hasDistributor ? undefined : true}>
        <AccordionSummary expandIcon={hasDistributor && <ExpandModeIcon/>}
                        sx={(t) => ({
                            backgroundColor: t.palette.Blue.light
                        })}>
            {hasDistributor && <Title text={t(`retailer.${distributor.name}`)}/>}
            {potato && <GridRow sx={t => ({ 
                    display: 'flex',
                    flexWrap: 'wrap',
                    width: '100%',
                    justifyContent: 'space-between',
                    'button': {
                        width: '49%'
                    }
                })}>
                <Button variant={!showAlts ? 'contained' : 'outlined'} onClick={() => setShowAlts(false)}>{t('common.basic')}</Button>
                <Button variant={showAlts ? 'contained' : 'outlined'} onClick={() => setShowAlts(true)}>+{t('calculator.extra-fertilizer')}</Button>
                {showAlts && <Typography variant="subtitle1">{t('calculator.info.header.alt')}</Typography>}
            </GridRow>}
        </AccordionSummary>
        <AccordionDetails sx={(t) => ({
            padding: 0,
            boxShadow: 'none'
            })}>
            {grass && output.usedInput.batchInputs.find(bi => bi.input > 0) && 
                <GridRow sx={{ display: 'inline-flex', width: '100%' }}>
                    <RowDataVersionButton title="manure" version={RowDataVersion.Manure}/>
                    <RowDataVersionButton title="fertilizer" version={RowDataVersion.Fertilizer}/>
                    <RowDataVersionButton title="total" version={RowDataVersion.Total}/>
                </GridRow>
            }
            {recommendations.map(recomm => <TableContainer key={`${distributor.name}-${recomm.number}`} sx={t => ({
                    marginBottom: '10px',
                    borderTop: '2px solid',
                    borderTopColor: t.palette.primary.light
                })}>
                <Table key={`${distributor.name}-${recomm.number}`}>
                    <TableHead>
                        <TableRow sx={(t) => ({
                            backgroundColor: t.palette.info.light
                        })}>
                            <FirstCell>
                                <b>{recomm.number}. { grass ? t('calculator.harvest') : t('calculator.solution')}</b>
                            </FirstCell>
                            <Cell align="right"><b>kg/ha</b></Cell>
                            <Cell align="right"><b>N</b></Cell>
                            <Cell align="right"><b>P</b></Cell>
                            <LastCell align="right"><b>K</b></LastCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {grass && <>
                            {rowDataVersion === RowDataVersion.Manure && <TableRow>
                                <FirstCell>
                                    <Typography>{t('calculator.manure')}</Typography>
                                </FirstCell>
                                {<RowData fert={recomm.fertilizers[0]}/>}
                            </TableRow>
                            }
                            {rowDataVersion !== RowDataVersion.Manure && <>
                                {grass && recomm.fertilizers.map((fert, i) => 
                                    <TableRow key={`${recomm.listKey}-${fert.fertilizer.code}`}>
                                        <FirstCell>
                                            <ProductName fert={fert}/>
                                        </FirstCell>
                                        {<RowData fert={fert}/>}
                                    </TableRow>
                                )}
                            </>}
                        </>}
                        {grain && recomm.fertilizers.map((fert, i) => 
                                <TableRow key={`${recomm.listKey}-${fert.fertilizer.code}`} sx={{
                                    position: 'relative'
                                }}>
                                    <FirstCell sx={{
                                        position: 'relative'
                                    }}>
                                        <Box sx={{
                                            float: 'right'
                                        }}>
                                            {fert.type !== 'extra' && <InitialFertilization width={35}/>}
                                            {fert.type === 'extra' && <ExtraFertilization  width={35}/>}
                                        </Box>
                                        <ProductName fert={fert}/>
                                    </FirstCell>
                                    {<RowData fert={fert}/>}
                                </TableRow>
                            )}
                        {potato && recomm.fertilizers.map((fert, i) => 
                            <Fragment key={`${recomm.listKey}-${fert.fertilizer.code}`}>
                                <TableRow sx={{
                                    position: 'relative'
                                }}>
                                    <FirstCell sx={{
                                        position: 'relative'
                                    }}>
                                        <ProductName fert={fert}/>
                                    </FirstCell>
                                    {<RowData fert={fert}/>}
                                </TableRow>
                                {fert.alt && <TableRow sx={(t) => ({
                                    position: 'relative',
                                    backgroundColor: t.palette.info.light
                                })}>
                                    <FirstCell sx={{
                                        position: 'relative'
                                    }}>
                                        <ProductName prefix={t('common.or')} fert={fert.alt}/>
                                    </FirstCell>
                                    {<RowData fert={fert.alt}/>}
                                </TableRow>}
                            </Fragment>
                        )}
                        {recomm.summaries.map((sum, i) => 
                            <TableRow key={`${recomm.listKey}-sum-${sum.ordinal}`} sx={{
                                paddingTop: 0, 
                                paddingBottom: 0, 
                                'td': {
                                    borderTop: i === 0 ? '2px lightgray solid' : '',
                                    paddingTop: 0, 
                                    paddingBottom: 0,
                                    '.MuiTypography-root': {
                                        fontWeight: i === 0 ? 'bold' : 'normal'
                                    }
                                }
                            }}>
                                <FirstCell>
                                    <Typography>{t(sum.name)}</Typography>
                                </FirstCell>
                                <Cell align="right"> </Cell>
                                <Cell align="right">{decimalFormat(sum.nitrogen)}</Cell>
                                <Cell align="right">{decimalFormat(sum.phosphorous, 1)}</Cell>
                                <LastCell align="right">{decimalFormat(sum.potassium)}</LastCell>
                            </TableRow>
                        )}
                        <RemarksAndRecomms listKey={recomm.listKey} remarks={recomm.remarks} />
                    </TableBody>
                </Table>
            </TableContainer>)}
            {infobox && <InfoBox box={infobox} />}
        </AccordionDetails>
    </Accordion>
}