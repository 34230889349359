import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useTranslation } from "react-i18next";


interface IProps {
    columns: string[];
    data: any[][];
}
export default function CommonTable(props: IProps){
    const { t } = useTranslation();

    return <TableContainer component={Paper} sx={(t) => ({
        //maxHeight: '500px',
    })}>
        <Table stickyHeader sx={(t) => ({
            'tr': {
                'td': {
                    paddingTop: 0,
                    paddingBottom: 0,
                },
                'th': {
                    fontWeight: 'bold',
                    color: t.palette.primary.contrastText,
                    backgroundColor: t.palette.primary.main
                },
                '&:hover': {
                    backgroundColor: t.palette.action.hover
                }
            }
        })}>
            <TableHead>
                <TableRow>
                    {props.columns.map((col, i) => 
                    <TableCell key={i}>{
                        t(col)}
                    </TableCell>
                    )}
                </TableRow>
            </TableHead>
            <TableBody>
                {props.data.map((row, i) => <TableRow key={i}>
                    {row.map((col, j) => 
                    <TableCell key={j}>
                        {col}
                    </TableCell>
                    )}
                </TableRow>)}
            </TableBody>
        </Table>
    </TableContainer>;
}