import { useTranslation } from "react-i18next";
import { IReport } from "../../../interfaces/IReport";
import { Bar } from "react-chartjs-2";
import { ChartColors } from "../../../consts/ChartColors";
import Loading from "../../Loading";
import ChartContainer from "./ChartContainer";

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

interface IProps {
    loading: boolean;
    data?: IReport;
    contained?: true;
    stacked?: boolean;
}
export default function CommonBarChart(props: IProps){
    const { t } = useTranslation();
            
    const {
        loading, 
        data,
        ...options
    } = props;

    return loading || !data ? <Loading show /> : <ChartContainer summary={data.summary}>
        <Bar
            options={{
                responsive: true,
                scales: {
                    x: {
                        stacked: options.stacked,
                    },
                    y: {
                        stacked: options.stacked,
                    }
                },
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        title: data.title ? {
                            display: true,
                            text: t(data.title)
                        } : undefined
                    },
                    datalabels: {
                        display: false
                    }
                }
            }}
            data={{
                labels: data.datasetValueTitles.map(t),
                datasets: data.datasets.map((dataset, i) => ({
                    label: t(data.datasetTitles[i]),
                    backgroundColor: ChartColors[dataset.color],
                    data: dataset.values,
                }))
            }}
        />
    </ChartContainer>;
}