import { Typography, TypographyPropsVariantOverrides } from "@mui/material";
import { Variant } from "@mui/material/styles/createTypography";
import { OverridableStringUnion } from "@mui/types";

export class TextToHtml {
    lastKey = 0;
        
    newKey = () => this.lastKey++;

    toElements = (raw: string, variant: OverridableStringUnion<Variant | 'inherit', TypographyPropsVariantOverrides> = 'caption'): JSX.Element[] => {
        const paragraphs = raw.trim().split('\\n');

        return paragraphs.map((p, i) => {
            let items: (JSX.Element|string)[] = [p];
            items = this.findElements(items, 'a');
            items = this.findElements(items, 'b');
            
            return (
            <Typography key={`p-${i}`} variant={variant} style={{whiteSpace: 'pre-line'}}>
                {items.map(item => typeof item === 'string' ? this.createSpan(item) : item)}
            </Typography>
            );
        });
    }

    private findElements = (items: (JSX.Element|string)[], el: string): (JSX.Element|string)[] => {
        const elements: (JSX.Element|string)[] = [];

        for (let item of items){
            if (typeof item !== 'string'){
                elements.push(item);
                continue;
            }
            item = item as string;

            const sections = this.findElement(item, el);
            if (sections.length === 1) {
                elements.push(sections[0]);
                continue;
            }
            
            elements.push(sections[0]);
            elements.push(sections[1]);
            elements.push(...this.findElements([sections[2]], el));
        }

        return elements;
    }

    private findElement = (text: string, el: string): (JSX.Element|string)[] => {
        const start = `<${el} `;
        const startIndex = text.indexOf(start);
        if (startIndex === -1)
            return [text];

        const before = text.substring(0, startIndex);
        const element = text.substring(startIndex);

        const end = `</${el}>`;
        const endIndex = element.indexOf(end);

        if (endIndex === -1)
            return [text];

        const after = element.substring(endIndex + end.length);
        
        return [before, this.createElement(element.substring(0, endIndex), el), after];
    }

    private createElement = (element: string, type: string): JSX.Element => {
        switch(type){
            case 'a': return this.createLink(element);
            case 'b': return this.createBold(element);
        }
        return this.createSpan('');
    }

    private createSpan = (text: string): JSX.Element => {
        return (<span key={`span-${this.newKey()}`}>{text}</span>)
    }

    private createBold = (boldElement: string): JSX.Element => {
        return (<b key={`bold-${this.newKey()}`}>{boldElement.substring(4)}</b>);
    }

    private createLink = (linkElement: string): JSX.Element => {
        let elementStart = linkElement.substring(2);
        const endOfStart = elementStart.indexOf('>');
        elementStart = elementStart.substring(0, endOfStart);

        const href = elementStart.trim();
        const content = linkElement.substring(linkElement.indexOf('>')+1);

        return (<a key={`link-${this.newKey()}`} href={href}>{content}</a>);
    }
}