import { IAutofillConfig, IGrainConfig, IGrassConfig, IPotatoConfig } from "../interfaces/ICalculatorConfig";
import { ICalculatorOutput } from "../interfaces/ICalculatorOutput";
import { ICalculatorPayload } from "../interfaces/payloads/ICalculatorInput";
import ApiServiceBase from "./ApiServiceBase";
import { yaraApi } from "./YaraApi";

export class FertilizerApi extends ApiServiceBase {

    grassConfig = (): Promise<IGrassConfig> => {
        return this.get<IGrassConfig>('calculator/grass');
    }

    grainConfig = (): Promise<IGrainConfig> => {
        return this.get<IGrainConfig>('calculator/grain');
    }

    potatoConfig = (): Promise<IPotatoConfig> => {
        return this.get<IPotatoConfig>('calculator/potato');
    }

    calculate = (input: ICalculatorPayload): Promise<ICalculatorOutput> => {
        yaraApi.action(`calculate>>plant>>${input.cultivatedPlant}`);
        const endpoint = input.cultivatedPlant === 1 ? 'calculator/grass'
            : input.cultivatedPlant === 6 ? 'calculator/potato'
            : 'calculator/grain';
        return this.post<ICalculatorOutput, ICalculatorPayload>(endpoint, input).then(result => {
            result.batches = result.batches.sort((a,b) => a.name.localeCompare(b.name));
            return result;
        });
    }

    autofillP = (pclass: number, pfix?: number): Promise<number> => {
        return this.get<number>(`calculator/grain/autofill/p/${pclass}/${pfix || 0}`);
    }

    grainAutofillNConfig = (plant: number, soil: number): Promise<IAutofillConfig> => {
        return this.get<IAutofillConfig>(`calculator/grain/autofill/n/${plant}/${soil}`);
    }

    potatoAutofillNConfig = (potato: number, cropLevel: number, soil: number): Promise<IAutofillConfig> => {
        return this.get<IAutofillConfig>(`calculator/potato/autofill/n/${potato}/${cropLevel}/${soil}`);
    }

    potatoAutofillP = (pclass: number): Promise<number> => {
        return this.get<number>(`calculator/potato/autofill/p/${pclass}`);
    }
}

export const fertilizerApi = new FertilizerApi(process.env.REACT_APP_FERT_API_URI as string);