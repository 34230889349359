
import { useEffect, useState } from "react";
import { yaraApi } from "../../services/YaraApi";

import { IReport } from "../../interfaces/IReport";
import CommonBarChart from "./common/CommonBarChart";
import CommonLineChart from "./common/CommonLineChart";
import TallChartContainer from "./common/TallChartContainer";

interface IProps {
    startDate: string;
    endDate: string;
}
export default function NewUsersReport({ startDate, endDate }: IProps){
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState<IReport[]>([]);
    
    useEffect(() => {
        setLoading(true);
        yaraApi.getReport('new-users', startDate, endDate).then(setData).finally(() => setLoading(false));
    }, [startDate, endDate]);

    return <>
        <CommonBarChart loading={loading} data={data[0]} />
        <TallChartContainer
            charts={[
                <CommonLineChart loading={loading} data={data[1]} />,
                <CommonLineChart loading={loading} data={data[2]} />
            ]}
        />
    </>;
}