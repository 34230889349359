import { Table, TableBody, TableContainer, TableHead, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IReportSummary } from "../../../interfaces/IReport";


interface IProps {
    data: IReportSummary
}
export default function ChartSummaryTable({ data }: IProps){
    const { t } = useTranslation();

    return <TableContainer sx={t => ({
        marginRight: t.spacing(2)
    })}>
        <Typography variant="subtitle1" fontWeight={'bold'}>{t(data.title)}</Typography>
        <Table sx={t => ({
            'td,th': {
                padding: t.spacing(0.5)
            },
            'tr': {
                '&:hover': {
                    backgroundColor: t.palette.action.hover
                }
            }
        })}>
            <TableHead sx={t => ({
                textAlign: 'left',
                border: `1px solid ${t.palette.divider}`,
            })}>
                {data.columns && <tr>
                    {data.columns.map((column, i) => <th key={`col-${column}-${i}`}>
                        <Typography variant="caption" fontWeight='bold'>{t(column)}</Typography>
                    </th>)}
                </tr>}
            </TableHead>
            <TableBody sx={t => ({
                textAlign: 'left',
                border: `1px solid ${t.palette.divider}`,
                'tr': {
                    border: `1px solid ${t.palette.divider}`
                }
            })}>
                {data.rows.map((row,i) => (
                    <tr key={`row-${data.title}-${i}`}>
                        {row.map((cell, j) => <td key={`row-${data.title}-${i}-${j}`}>
                            <Typography variant="caption">{t(cell)}</Typography>
                        </td>)}
                    </tr>
                ))}
            </TableBody>
        </Table>
    </TableContainer>
}